<template>
  <div class="about-container">
    <h2>About</h2>
    <p>
      Bringing you the most inspirational portfolios around the world.
    </p>
    <p>
      Pfolios.net is a project made by
      <a href="http://twitter.com/ahmetsulek" target="_blank">
        Ahmet Sülek
      </a>
      for
      <a href="http://asteya.network" target="_blank"> Asteya Network </a>.
    </p>
    <p>
      We reach more than 900.000 unqiue creatives monthly. Head on Asteya
      Network website to advertise your product for the best rates you can ever
      find.
    </p>
    <p>
      Special thanks to
      <a href="http://twitter.com/dogagenc" target="_blank"> Doga Genc</a> for
      deployment and database scripts.
    </p>
  </div>
  <!-- /.about-container -->
</template>

<script>
export default {
  name: "About",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.about-container {
  display: block;
  width: calc(100% - 10px);
  h2 {
    margin-bottom: 20px;
    margin-top: $height-page-margin-top + 14px;
  }
  p {
    float: left;
    @include p();
    margin-top: 10px;
    margin-bottom: 5px;
    a {
      @include a_folios();
      text-decoration: underline;
    }
  }
  p:nth-child(1) {
    background: red;
  }
}
@media (max-width: $mobile-max) {
  .about-container {
    display: block;
  }
}
</style>
